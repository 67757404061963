/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-else-return */
/* eslint-disable import/no-duplicates */
/* eslint-disable import/order */
import { useState, useEffect, Key } from "react";
import { observer } from "mobx-react-lite";
import { Decimal } from "decimal.js";
import Login from 'components/Login'
import { Modal, Button } from 'antd'
import {
  getFarmAddress,
  getPoolAddress
} from 'utils/addressHelpers'
// import {  } from '@dreamweb/uikit'
import { useUpdate } from 'ahooks'
// import { approve as approveF } from 'utils/callHelpers'
import useWeb3 from 'hooks/useWeb3'

import { useTokenBalance } from 'hooks/useTokenBalance'
import { formatDecimal } from 'utils/math'
import { getBep20Contract } from 'utils/contractHelpers'
import { useWeb3React } from '@web3-react/core'
import _ from 'lodash'
// import { percentileSegmentation } from 'utils/utils'
import { useHistory } from "react-router-dom";
import { width } from "styled-system";
import Tokens from './Tokens'
import { getLinkBnbLink } from 'utils/addressHelpers'

interface CardProps {
  key: Key,
  windowSize: number
  [key: string]: any
}

const List = (props: any) => {
  const { setIsUpdate, userInfo, lpContract, getPending, itemData, windowSize, showDetailClick, deposit, approve, claim, withdraw } = props
  const { token: { tokenArr, addArr }, isFarms, idx } = itemData
  const history = useHistory()
  const web3 = useWeb3()
  const update = useUpdate();
  const [data, setData] = useState(itemData)
  const [isApprove, setIsApprove] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isHarvestLoading, setIsHarvestLoading] = useState(false)
  const [isStackToken, setIsStackToken] = useState(false)
  const [isStackToken1, setIsStackToken1] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPledge, setIsPledge] = useState(false)
  const [earnedNum, setEarnedNum] = useState(0)
  // const [tokenArr, setTokenArr]=useState<any>([])
  // const [addArr, setAddArr]=useState<any>([])
  const [staked, setStaked] = useState(0)
  const [timer, setTimer] = useState(null)
  const [showListDetial, setshowListDetial] = useState<boolean>(data.showDetail)
  const { account } = useWeb3React()
  const disBtnClass = 'bg-black5 text-text1'
  const balance = useTokenBalance(itemData?.paycoin)




  // 获取token0 - token1
  // const getToken = async () => { 
  //   if(isFarms) {
  //     console.log(2)
  //     console.log('获取token0 - token1', data?.paycoin)
  //     const res = await lpContract.methods.getReverseTokens(data?.paycoin).call()
  //     getIconName(res)
  //     console.log('token0 - token1', res)
  //   } else {
  //     console.log(3, isFarms)
  //     getIconName(null)
  //   }
  // }



  // getIconName
  // const getIconName = async (e) => { 
  //   const paycoinContract = await getBep20Contract(data?.paycoin)
  //   const getPaycoinToken1 = await paycoinContract.methods.symbol().call()
  //   if(e) {
  //     const token0Contract = await getBep20Contract(e?.[0])
  //     const token1Contract = await getBep20Contract(e?.[1])
  //     const getToken0 = await token0Contract.methods.symbol().call()
  //     const getToken1 = await token1Contract.methods.symbol().call()
  //     console.log(idx, 'token0 token1', data?.paycoin, getPaycoinToken1, getToken0, getToken1)
  //     if(!_.isEqual([getPaycoinToken1, getToken0, getToken1],tokenArr)) {
  //       setTokenArr([getPaycoinToken1, getToken0, getToken1])
  //       setAddArr([data?.paycoin, e?.[0], e?.[1]])
  //     }
  //   } else {
  //     setTokenArr([getPaycoinToken1])
  //     setAddArr([data?.paycoin])
  //   }
  // }

  useEffect(() => {
    // if(!data?.paycoin) {
    //   return
    // }
    // getToken()
  }, [data?.paycoin])



  const getUserInfo = async () => {
    const stake1 = await userInfo(idx)
    setStaked(new Decimal(stake1?.staked).div(1e18).toNumber())
  }

  useEffect(() => {
    setData(itemData)
    paycoinAllowance(itemData?.paycoin)
    if (account) {
      getUserInfo()
    }
  }, [itemData, account])

  // 查询是否授权
  const paycoinAllowance = async (address) => {
    if (!account) {
      return
    }
    // 查询是否授权
    // console.log('查询是否授权', address)
    const maxNum = await getBep20Contract(address, web3).methods.allowance(account, data?.farmAddress).call()

    // console.log('查询是否授权', maxNum)
    // 未授权时授权
    if (!+maxNum) {
      setIsApprove(false)
    } else {
      setIsApprove(true)
    }
  }


  const addStake = (type: number) => {
    // 
  }
  const unStake = (type: number) => {
    // 
  }

  // const getPending = async () => {
  //   // 
  // }

  useEffect(() => {
    const fn = async () => {
      const num = await getPending(idx)
      setEarnedNum(num)
      setIsPledge(!!+num)
    }
    fn()
    clearInterval(timer)
    const newTimer = setInterval(() => {
      fn()
    }, 3000)
    setTimer(newTimer)
    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    if (isFarms && idx === 0) {
      localStorage.setItem('add', `/add/${addArr?.[1]}/${addArr?.[2]}`)
    }
  }, [addArr?.[1]])

  if (!tokenArr?.[0]) return ''

  if (windowSize < 769) {
    return (
      <div style={{ marginBottom: '15px', borderRadius: '20px', overflow: 'hidden' }} className={`list w-full relative over `}>

        {/* {showRequiredModel && (<RequiredModel show={showRequiredModel} baseTokenSymbol={data.baseTokenSymbol} onChangeShow={setRequiredModel} />)}
        {showStakeInPoolModel && (<StakeInPool baseTokenSymbol={data.baseTokenSymbol} balace={data.userInfo.baseBalance} show={showStakeInPoolModel} onChangeShow={setShowStakeInPoolModel} onConfimValue={confirmDeposit} data={data} />)}
        {showUnStakeModel && (<Unstake baseTokenSymbol={data.baseTokenSymbol} balace={data.userInfo.amount} show={showUnStakeModel} onChangeShow={setShowUnStakeModel} onConfimValue={confirmWithdraw} data={data} />)} */}


        <div style={{ background: '#444' }} className="list-top bg-black2 pt-4 px-4">
          <div className="flex justify-start items-center">
            <div style={{ width: 46 }} className="relative mr-4">
              <img src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/coins/${isFarms ? tokenArr?.[1] || 'USDT' : tokenArr?.[0] || 'DCOIN'}.png`} alt="" className="" style={{ width: '45px' }} />
              <div className="absolute -right-2 -bottom-2 w-6 h-6 rounded-c bg-btn">
                <img src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/coins/${isFarms ? tokenArr?.[2] || 'DCOIN' : 'DCOIN'}.png`} alt="" />
              </div>
            </div>
            <div className="flex flex-col items-start">
              {
                isFarms ?
                  <>
                    <span className="text-text2 text-6xl font-medium">{tokenArr?.[1]}/{tokenArr?.[2]} LP</span>
                    <span className="text-text1 text-4xl">Earn DCOIN</span>
                  </> :
                  <>
                    <span className="text-text2 text-6xl font-medium">Stake  {tokenArr?.[0]}</span>
                    <span className="text-text1 text-2xl">Earn DCOIN</span>
                  </>
              }
            </div>
          </div>
          <div style={{ paddingBottom: '15px' }} className="py-6">
            <div style={{ paddingBottom: '13px' }} className="flex justify-between items-center">
              {
                !isFarms ?
                  <>
                    <div className="flex flex-col items-start">
                      <span className="text-text1 text-2xl">DCOIN Earned</span>
                      <span className="text-text2 text-7xl">{formatDecimal(new Decimal(earnedNum || 0).div(1e18).toFixed(7), 6, true)}</span>
                    </div>
                  </> :
                  <>
                    <div className="flex flex-col items-start">
                      <span className="text-text1 text-2xl">DCOIN Earned</span>
                      <span className="text-text2 text-7xl">{formatDecimal(new Decimal(earnedNum || 0).div(1e18).toFixed(7), 6, true)}</span>
                    </div>
                  </>
              }
              <div className="flex flex-col items-start">
                <span className="text-text1 text-2xl">APY</span>
                <span className="text-text2 text-7xl">{data?.apy / 100}%</span>
              </div>
            </div>
            <div style={{ width: '100%' }} className="noselect w-20 z-20" onClick={() => {
              setshowListDetial(!showListDetial)
              showDetailClick(data?.index)
            }}>
              {
                showListDetial ? (
                  <div className="flex justify-center items-center">
                    <span className="text-text2 text-6xl font-medium mr-2">Hide</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg" width="6" height="3.667" viewBox="0 0 6 3.667" fill="#CDCDCD">
                      <g transform="translate(6 3.667) rotate(180)">
                        <path d="M3.471,5.8a.667.667,0,0,0,0-.943L1.609,3,3.471,1.138A.667.667,0,0,0,2.529.2L.2,2.529a.667.667,0,0,0,0,.943L2.529,5.8A.667.667,0,0,0,3.471,5.8Z" transform="translate(0 3.667) rotate(-90)" />
                      </g>
                    </svg>
                  </div>
                ) : (
                  <div className="flex justify-center items-center">
                    <span className="text-text4 text-6xl font-medium mr-2">Details</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg" width="6" height="3.667" viewBox="0 0 6 3.667" fill="#CDCDCD">
                      <g transform="translate(0 0)">
                        <path d="M3.471,5.8a.667.667,0,0,0,0-.943L1.609,3,3.471,1.138A.667.667,0,0,0,2.529.2L.2,2.529a.667.667,0,0,0,0,.943L2.529,5.8A.667.667,0,0,0,3.471,5.8Z" transform="translate(0 3.667) rotate(-90)" />
                      </g>
                    </svg>
                  </div>
                )
              }
            </div>

          </div>
        </div>
        <div style={{ background: '#333' }} className={`list-bottom bg-black4 flex flex-col justify-start items-center ${showListDetial ? 'h-auto p-4' : 'h-0 overflow-hidden'}`}>
          {/* 测试列表样式 */}
          {
            true && (
              <div className="w-full">
                <div style={{ border: '2px solid' }} className="border border-line rounded-12 p-4 flex flex-col items-start justify-around w-full h-32 mb-4">
                  <span className="text-text2 text-2xl">DCOIN EARNED</span>
                  <div className="flex justify-between items-center w-full">
                    <div className="flex flex-col text-4xl">
                      <span className="text-text2 text-6xl">{formatDecimal(new Decimal(earnedNum || 0).div(1e18).toFixed(9), 8, true)}</span>
                    </div>
                    <Button
                      disabled={!+earnedNum}
                      loading={isHarvestLoading}
                      style={{ width: '75px', height: '30px', background: '#fff', color: '#000', borderRadius: '4px', border: 'none', opacity: !+earnedNum || isLoading ? '0.7' : '' }}
                      onClick={async () => {
                        try {
                          setIsHarvestLoading(true)
                          await claim(idx)
                          setIsHarvestLoading(false)
                        } catch (error) {
                          setIsHarvestLoading(false)
                        }
                      }} className="flex justify-center items-center w-28 h-12 rounded-12 text-9xl" >
                      Harvest
                    </Button>
                  </div>
                </div>
              </div>
            )
          }

          <div style={{ border: '2px solid' }} className="border border-line rounded-12 p-4 flex flex-col items-start justify-around w-full h-32 mb-4">
            {
              account && !isPledge &&
              <>
                {
                  isFarms ?
                    <span className="text-text2 text-2xl">LP STAKE</span> :
                    <span className="text-text2 text-2xl">{tokenArr?.[0]} STAKE</span>
                }
              </>
            }
            {
              !account &&
              <Login
                style={{ width: '100%' }}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
              >
                <div style={{ width: '340px', height: '56px', background: 'rgb(255, 255, 255)', marginTop: '10px' }} className="styledHeaderTextC-hover styledHeaderTextC corsor-pointer">Connect Wallet</div>
              </Login>
            }
            {/* 登录但是没有授权时展示 */}
            {account && !isApprove && (
              <Button loading={isLoading} style={{ color: '#000' }} onClick={async () => {
                try {
                  setIsLoading(true)
                  await approve(data.paycoin)
                  setIsLoading(false)
                } catch (error) {
                  setIsLoading(false)
                }
                paycoinAllowance(itemData?.paycoin)
              }} className="text-text1 text-6xl flex justify-center items-center w-full h-12 bg-btn rounded-12">Enable</Button>
            )}
            {/* 授权展示 */}
            {account && isApprove &&
              <>
                {
                  isPledge ?
                    <div className="border border-line rounded-12 flex flex-col items-start justify-around w-full h-32 mb-4">
                      <span className="text-text2 text-2xl">{isFarms ? 'LP' : tokenArr?.[0]} STAKEDSTAKED</span>
                      <div className="flex justify-between items-center w-full">
                        <div className="flex flex-col">
                          <span className="text-text2 text-10xl">{formatDecimal(new Decimal(staked).toFixed(), 4)}</span>
                          {/* <span className="text-¬text1 text-2xl">~ xxx USDC</span> */}
                        </div>
                        <div className="flex justify-center items-center">
                          <Button onClick={() => {
                            setIsAdd(false)
                            setIsStackToken1(true)
                          }} style={{ border: '1px solid #fff', color: '#fff' }}
                            className="flex justify-center items-center w-12 h-12 mr-4 rounded-12 border border-btn text-text" >-</Button>
                          <Button onClick={() => {
                            setIsAdd(true)
                            setIsStackToken(true)
                          }} style={{ border: '1px solid #fff', color: '#fff' }}
                            className="flex justify-center items-center w-12 h-12 rounded-12 border border-btn text-text2">+</Button>
                        </div>
                      </div>
                    </div> :
                    (
                      <Button style={{ color: '#000' }} onClick={() => {
                        setIsAdd(true)
                        setIsStackToken(true)
                      }} className="text-text1 text-6xl flex justify-center items-center w-full h-12 bg-btn rounded-12">Stake</Button>
                    )
                }
              </>
            }
          </div>

          <div className="w-full mt-2">
            {/* <div className="flex justify-between items-center text-2xl">
              <span className="text-text1">APY:</span>
              <span className="text-text2">{data?.apy/100}%</span>
            </div> */}
            {
              isFarms ?
                <>
                  <div className="flex justify-between items-center text-2xl">
                    <span className=" text-text2">Total Staked:</span>
                    <span className=" text-text2">{new Decimal(data?.totalStaked || 0).div(1e18).toFixed() || 0}  {isFarms ? 'LP' : tokenArr?.[0]}</span>
                  </div>
                </> :
                <>
                  <div className="flex justify-between items-center text-2xl">
                    <span className=" text-text2">Total Staked:</span>
                    <span className=" text-text2">{new Decimal(data?.totalStaked || 0).div(1e18).toFixed() || 0} {isFarms ? 'LP' : tokenArr?.[0]}</span>
                  </div>
                </>
            }
            <div className="flex justify-between items-center text-2xl">
              <span className="text-text2">Start Time:</span>
              <span className="text-text2">08:11:32</span>
            </div>
            {
              // isFarms && 
              // <>
              //   <div className="flex justify-between items-center text-2xl mb-4">
              //     <span className=" text-text1">Staked:</span>
              //     <span className=" text-text2">0 LP</span>
              //   </div>
              // </>
            }
            <div onClick={() => {
              window.open(`${getLinkBnbLink()}token/${addArr?.[0]}`)
            }} className="hover-link py-1 flex justify-center items-center text-text4 text-2xl rounded-90 border border-btn" >See Token Info</div>
            {
              isFarms &&
              <div onClick={() => history.push(`/add/${addArr?.[1]}/${addArr?.[2]}`)} className="hover-link py-1 flex justify-center items-center text-text4 text-2xl rounded-90 border border-btn" >Add {tokenArr?.[1]}/{tokenArr?.[2]} LP</div>
            }
            <div onClick={() => {
              window.open(`${getLinkBnbLink()}address/${isFarms ? getFarmAddress() : getPoolAddress()}`)
            }} className="hover-link py-1 flex justify-center items-center text-text4 text-2xl rounded-90 border border-btn" >View Contract</div>
          </div>
        </div>
        <Modal centered className='buy-tickets-modal' title={null} footer={null} open={isStackToken} closable={false}>
          <Tokens isFarms={isFarms} link={`/add/${addArr?.[1]}/${addArr?.[2]}`} name={`${tokenArr?.[1]}/${tokenArr?.[2]}`} staked={staked} lpContract={lpContract} tokenArr={tokenArr} idx={idx} isAdd={isAdd} address={data?.paycoin} onDismiss={() => setIsStackToken(false)}
            deposit={async (e, v) => {
              await deposit(e, v)
              getUserInfo()
            }}
            withdraw={async (e, v) => {
              await withdraw(e, v)
              getUserInfo()
            }} />
        </Modal>
        <Modal centered className='buy-tickets-modal' title={null} footer={null} open={isStackToken1} closable={false}>
          <Tokens isFarms={isFarms} link={`/add/${addArr?.[1]}/${addArr?.[2]}`} name={`${tokenArr?.[1]}/${tokenArr?.[2]}`} staked={staked} lpContract={lpContract} tokenArr={tokenArr} idx={idx} isAdd={isAdd} address={data?.paycoin} onDismiss={() => setIsStackToken1(false)}
            deposit={async (e, v) => {
              await deposit(e, v)
              getUserInfo()
            }}
            withdraw={async (e, v) => {
              await withdraw(e, v)
              getUserInfo()
            }} />
        </Modal>
      </div>
    )
  } else {
    return (
      <div style={{ marginBottom: '15px', borderRadius: '20px' }} className="list w-full relative over overflow-hidden">

        <div style={{ background: '#444' }} className="list-top bg-black2 flex items-center py-8 px-8 justify-between">
          <div className="flex justify-start items-center">
            <div style={{ width: 46 }} className="relative mr-4">
              <img src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/coins/${isFarms ? tokenArr?.[1] || 'USDT' : tokenArr?.[0] || 'DCOIN'}.png`} alt="" className="" style={{ width: '145px' }} />
              <div className="absolute -right-2 -bottom-2 w-6 h-6 rounded-c bg-btn">
                <img src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/coins/${isFarms ? tokenArr?.[2] || 'DCOIN' : 'DCOIN'}.png`} alt="" />
              </div>
            </div>
            <div className="flex flex-col items-start mr-20 w-28">
              {
                isFarms ?
                  <>
                    <span className="text-text2 text-6xl font-medium" style={{ whiteSpace: 'nowrap' }}>{tokenArr?.[1]}/{tokenArr?.[2]} LP</span>
                    <span className="text-text1 text-2xl" style={{ whiteSpace: 'nowrap' }}>Earn DCOIN</span>
                  </> :
                  <>
                    <span className="text-text2 text-6xl font-medium" style={{ whiteSpace: 'nowrap' }}>Stake {tokenArr?.[0]}</span>
                    <span className="text-text1 text-2xl ">Earn DCOIN</span>
                  </>
              }
            </div>
          </div>
          <div className={isFarms ? "grid grid-cols-4 w-full" : "grid grid-cols-4 w-full"}>
            <div className="flex flex-col items-start" style={{ maxWidth: '130px' }}>
              {
                isFarms ?
                  <>
                    <span className="text-text1 text-2xl">DCOIN Earned</span>
                    <span className="text-text2 text-7xl">{formatDecimal(new Decimal(earnedNum || 0).div(1e18).toFixed(7), 6, true)}</span>
                  </> :
                  <>
                    <span className="text-text1 text-2xl">DCOIN Earned</span>
                    <span className="text-text2 text-7xl">{formatDecimal(new Decimal(earnedNum || 0).div(1e18).toFixed(7), 6, true)}</span>
                  </>
              }
            </div>
            <div className="flex flex-col items-start" style={{ maxWidth: '170px' }}>
              {
                isFarms ?
                  <>
                    <span className="text-text1 text-2xl">Total staked</span>
                    <span className="text-text2 text-7xl">{new Decimal(data?.totalStaked || 0).div(1e18).toFixed() || 0}  {isFarms ? 'LP' : tokenArr?.[0]}</span>
                  </> :
                  <>
                    <span className="text-text1 text-2xl">Total Staked </span>
                    <span className="text-text2 text-7xl">{new Decimal(data?.totalStaked || 0).div(1e18).toFixed() || 0} {isFarms ? 'LP' : tokenArr?.[0]}</span>
                  </>
              }
            </div>
            <div className="flex flex-col items-start" style={{ maxWidth: '130px' }}>
              <span className="text-text1 text-2xl">APY</span>
              <span className="text-text2 text-7xl">{data?.apy / 100}%</span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-text1 text-2xl">Start Time</span>
              <span className="text-text2 text-7xl">08:11:32</span>
            </div>
            {
              // isFarms && 
              // <>
              // <div className="flex flex-col items-start" style={{ maxWidth: '130px' }}>
              //   <span className="text-text1 text-2xl">Staked</span>
              //   <span className="text-text2 text-7xl">0 LP</span>
              // </div>
              // </>
            }
          </div>
          {/* 展开收起 */}
          <div className=" cursor-pointer noselect w-20 z-20" onClick={() => {
            setshowListDetial(!showListDetial)
          }}>
            {/* 测试列表样式 */}
            {
              showListDetial ? (
                <div className="flex justify-center items-center">
                  <span className="text-text2 text-6xl font-medium mr-2">Hide</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg" width="6" height="3.667" viewBox="0 0 6 3.667" fill="#CDCDCD">
                    <g transform="translate(6 3.667) rotate(180)">
                      <path d="M3.471,5.8a.667.667,0,0,0,0-.943L1.609,3,3.471,1.138A.667.667,0,0,0,2.529.2L.2,2.529a.667.667,0,0,0,0,.943L2.529,5.8A.667.667,0,0,0,3.471,5.8Z" transform="translate(0 3.667) rotate(-90)" />
                    </g>
                  </svg>
                </div>
              ) : (
                <div className="flex justify-center items-center">
                  <span className="text-text4 text-6xl font-medium mr-2">Details</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg" width="6" height="3.667" viewBox="0 0 6 3.667" fill="#CDCDCD">
                    <g transform="translate(0 0)">
                      <path d="M3.471,5.8a.667.667,0,0,0,0-.943L1.609,3,3.471,1.138A.667.667,0,0,0,2.529.2L.2,2.529a.667.667,0,0,0,0,.943L2.529,5.8A.667.667,0,0,0,3.471,5.8Z" transform="translate(0 3.667) rotate(-90)" />
                    </g>
                  </svg>
                </div>
              )
            }
          </div>
        </div>
        <div style={{ background: '#333' }} className={`list-bottom bg-black4 px-8 flex justify-between items-center transition-all duration-500 ease-in-out ${showListDetial ? 'h-44 overflow-auto	' : 'h-0 overflow-hidden'}`}>
          <div className=" flex flex-col justify-between">
            {
              // !isFarms && 
              // <>
              //   <div className="grid grid-cols-2 gap-1 text-2xl">
              //     <span className="text-text1">APY:</span>
              //     <span className="text-right	text-text2">{data.apy/100}%</span>
              //   </div>
              //   <div className="grid grid-cols-2 gap-1 text-2xl">
              //     <span className="text-text1">Total Staked:</span>
              //     <span className="text-right	text-text2">0 DCOIN</span>
              //   </div>
              //   <div className="grid grid-cols-2 gap-1 text-2xl  mb-4">
              //     <span className=" text-text1">Start Time:</span>
              //     <span className="text-right	 text-text2 te">08:11:32</span>
              //   </div>
              // </>
            }

            <div onClick={() => {
              window.open(`https://bscscan.com/token/${addArr?.[0]}`)
            }} className="hover-link py-1 flex justify-center items-center text-text2 text-2xl rounded-90 border border-btn mb-2 lg:cursor-pointer">See Token Info</div>
            {
              isFarms &&
              <div onClick={() => history.push(`/add/${addArr?.[1]}/${addArr?.[2]}`)} className="hover-link py-1 flex justify-center items-center text-text2 text-2xl rounded-90 border border-btn mb-2 lg:cursor-pointer">Add {tokenArr?.[1]}/{tokenArr?.[2]} LP</div>
            }
            <div onClick={() => {
              window.open(`https://bscscan.com/address/${isFarms ? getFarmAddress() : getPoolAddress()}`)
            }} className="hover-link py-1 flex justify-center items-center text-text2 text-2xl rounded-90 border border-btn lg:cursor-pointer">View Contract</div>
          </div>
          <div className="flex justify-end items-center">
            <div style={{ border: '1px solid #EFEFEF', background: '#414141' }} className="border border-line rounded-12 p-4 flex flex-col items-start justify-around w-80 2xl:w-96 h-32 ">
              <span className="text-text2 text-2xl">DCOIN EARNED</span>
              <div style={{ width: '100%' }} className="flex justify-between items-center">
                <div className="flex flex-col text-4xl">
                  <span className="text-text2 text-8xl">{formatDecimal(new Decimal(earnedNum || 0).div(1e18).toFixed(10), 8, true)}</span>
                </div>
                <Button
                  disabled={!+earnedNum}
                  loading={isHarvestLoading}
                  onClick={async () => {
                    try {
                      setIsHarvestLoading(true)
                      await claim(idx)
                      setIsHarvestLoading(false)
                    } catch (error) {
                      setIsHarvestLoading(false)
                    }
                    setIsLoading(false)
                  }} className="flex justify-center items-center" style={{ width: '75px', height: '30px', background: '#fff', color: '#000', borderRadius: '4px', border: 'none', opacity: !+earnedNum || isLoading ? '0.7' : '' }}>
                  Harvest
                </Button>
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center">
            <div style={{ border: '1px solid #EFEFEF', background: '#414141' }} className="border border-line rounded-12 p-4 flex flex-col items-start justify-around w-80 2xl:w-96 h-32 ">
              {
                !isPledge &&
                <>
                  {
                    isFarms ?
                      <span className="text-text2 text-2xl">LP STAKED</span> :
                      <span className="text-text2 text-2xl">{tokenArr?.[0]} STAKED</span>
                  }
                </>
              }
              {!account &&
                <Login
                  style={{ width: '100%' }}
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
                >
                  <div style={{ width: '340px', height: '56px', background: 'rgb(255, 255, 255)', marginTop: '10px' }} className="styledHeaderTextC-hover styledHeaderTextC corsor-pointer">Connect Wallet</div>
                </Login>
              }
              {/* cursor-no-drop */}
              {
                account && !isApprove &&
                <Button loading={isLoading} style={{ color: '#000' }} onClick={async () => {
                  setIsLoading(true)
                  try {
                    await approve(data.paycoin)
                  } catch (error) {
                    // 
                  }
                  setIsLoading(false)
                  paycoinAllowance(itemData?.paycoin)
                }} className="text-text1 text-6xl flex justify-center items-center w-full h-12 bg-btn rounded-12">Enable</Button>
              }
              {
                account && isApprove &&
                <>
                  {
                    isPledge ?
                      <div style={{ width: '100%' }} className="border border-line rounded-12 p-4 flex flex-col items-start justify-around 2xl:w-96 h-32">
                        <span className="text-text2 text-2xl">{isFarms ? 'LP' : tokenArr?.[0]} STAKED</span>
                        <div className="flex justify-between items-center w-full">
                          <div className="flex flex-col">
                            <span className="text-text2 text-10xl">{formatDecimal(new Decimal(staked).toFixed(), 4)}</span>
                            {/* <span className="text-¬text1 text-2xl">~ xxx USDC</span> */}
                          </div>
                          <div className="flex justify-center items-center">
                            <Button onClick={() => {
                              setIsAdd(false)
                              setIsStackToken1(true)
                            }} style={{ border: '1px solid #fff', color: '#fff' }} className="flex justify-center items-center w-12 h-12 mr-4 rounded-12 border border-btn text-text2">-</Button>
                            <Button onClick={() => {
                              setIsAdd(true)
                              setIsStackToken(true)
                            }} style={{ border: '1px solid #fff', color: '#fff' }} className="flex justify-center items-center w-12 h-12 rounded-12 border border-btn text-text2">+</Button>
                          </div>
                        </div>
                      </div> :
                      <Button style={{ color: '#000', border: 'none' }} onClick={() => {
                        setIsAdd(true)
                        setIsStackToken(true)
                      }} className="text-text1 text-6xl flex justify-center items-center w-full h-12 bg-btn rounded-12">
                        Stake
                      </Button>
                  }
                </>
              }
            </div>
          </div>
        </div>

        <Modal centered className='buy-tickets-modal' title={null} footer={null} open={isStackToken} closable={false}>
          <Tokens isFarms={isFarms} link={`/add/${addArr?.[1]}/${addArr?.[2]}`} name={`${tokenArr?.[1]}/${tokenArr?.[2]}`} staked={staked} lpContract={lpContract} tokenArr={tokenArr} idx={idx} isAdd={isAdd} address={data?.paycoin} onDismiss={() => setIsStackToken(false)}
            deposit={async (e, v) => {
              await deposit(e, v)
              getUserInfo()
            }}
            withdraw={async (e, v) => {
              await withdraw(e, v)
              getUserInfo()
            }} />
        </Modal>
        <Modal centered className='buy-tickets-modal' title={null} footer={null} open={isStackToken1} closable={false}>
          <Tokens isFarms={isFarms} link={`/add/${addArr?.[1]}/${addArr?.[2]}`} name={`${tokenArr?.[1]}/${tokenArr?.[2]}`} staked={staked} lpContract={lpContract} tokenArr={tokenArr} idx={idx} isAdd={isAdd} address={data?.paycoin} onDismiss={() => setIsStackToken1(false)}
            deposit={async (e, v) => {
              await deposit(e, v)
              getUserInfo()
            }}
            withdraw={async (e, v) => {
              await withdraw(e, v)
              getUserInfo()
            }} />
        </Modal>
      </div>
    )
  }
}

export default observer(List)